import { Loader } from '@googlemaps/js-api-loader'

import Block from '../block'

export default class BlockGoogle extends Block {
	static title = 'block-google'

	constructor($el) {
		super($el)

		let map
		this.mapDiv = this.$el.findElement('map')

		if (!this.$el.data('lat') || !this.$el.data('lng')) {
			return
		}

		if (!this.mapDiv.get(0)) {
			return
		}

		const mapOptions = {
			center: {
				lat: parseFloat(this.$el.data('lat')),
				lng: parseFloat(this.$el.data('lng')),
			},
			mapTypeControlOptions: {
				mapTypeIds: [],
			},
			streetViewControl: false,
			zoom: 17,
			mapId: this.$el.data('style') ? this.$el.data('style') : 'PAGEWIZE',
		}

		const loader = new Loader({
			apiKey: 'AIzaSyAAa2JtezAnWENSSyBfh0Rovv3jeN5E-tg',
			version: 'weekly',
			libraries: ['places'],
		})

		loader
			.importLibrary('maps')
			.then(async () => {
				map = new google.maps.Map(this.mapDiv.get(0), mapOptions)

				const { AdvancedMarkerElement } = await loader.importLibrary('marker')
				new AdvancedMarkerElement({ map, position: mapOptions.center })
			})
			.catch((e) => {
				// eslint-disable-next-line no-console
				console.log(e)
			})
	}
}
