import Block from '../block'

export default class BlockGallery extends Block {
	static title = 'block-gallery'

	constructor($el) {
		super($el)

		this.content = this.$el.findElement('content')

		$(document).on('carousel:change-slide', (event, data) => {
			if (data.title && data.caption) {
				this.content.removeClass('hidden')
				if (this.$el.data('id') == data.id) {
					this.$el.findElement('title').text(data.title)
					this.$el.findElement('text').html(data.caption)
				}
			} else {
				this.content.addClass('hidden')
			}
		})
	}
}
